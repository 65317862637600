<template>
  <div>
    <div class="row">
      <InputGenericRadioOption
        :model="$v.form.system_timezone_setting_id"
        class="col-xl-12 px-5 mb-0"
        :submissionStates="submissionStates"
        id="input-system-timezone-setting"
        @radio_option_changed="changeTimezoneSetting()"
        :displayLabel="false"
        :error="$t('FORMS.ERROR.SYSTEM_TIMEZONE_SETTING')"
        :options="[
          {
            value: 1,
            text: $t('FORMS.INPUTS.TIMEZONE_SETTING_LOCAL'),
            tooltip: $t('TIMEZONE.TOOLTIPS.LOCAL')
          },
          {
            value: 2,
            text: $t('FORMS.INPUTS.TIMEZONE_SETTING_PROJECT'),
            tooltip: $t('TIMEZONE.TOOLTIPS.PROJECT')
          },
          {
            value: 3,
            text: $t('FORMS.INPUTS.TIMEZONE_SETTING_USER'),
            tooltip: $t('TIMEZONE.TOOLTIPS.USER')
          }
        ]"
      >
      </InputGenericRadioOption>
    </div>
    <ul v-if="form.system_timezone_setting_id == 3" class="navi navi-hover" style="height:300px; overflow-y:auto">
      <template v-for="(item, i) in systemTimezones">
        <li
          class="navi-item"
          :class="{ 'navi-item-active bg-secondary': isActiveTimezone(item.id) }"
          :key="i"
        >
          <a
            href="javascript:void(0)"
            class="navi-link px-2"
            v-bind:data-time="item.id"
            @click="changeTimezone(item.code)"
          >
            <span class="navi-text" style="width:240px">{{ item.name }}</span>
            <span class="navi-text" style="width:50px">{{ item.offset }}</span>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

import { 
  UPDATE_USER_TIMEZONE,
  UPDATE_USER_TIMEZONE_SETTING
} from "@/core/services/store/core/user_timezone.module.js";
import { POPULATE_TIMEZONES } from "@/core/services/store/system/system.module";
import InputGenericRadioOption from "@/view/components/form-inputs/GenericRadioOption";



export default {
  mixins: [validationMixin],

  name: "KTDropdownTimezone",

  data() {
    return {
      submissionStates: {
        submitting: false
      },
      form: {
        system_timezone_setting_id: 1
      }
    }
  },

  validations: {
    form: {
      system_timezone_setting_id: {
        required
      }
    }
  },

  components: {
    InputGenericRadioOption
  },

  mounted() {
    this.getTimezones();
    this.prepopulateUserTimezoneSetting();
  },

  methods: {
    prepopulateUserTimezoneSetting() {
      this.form.system_timezone_setting_id = this.currentUser.system_timezone_setting_id;
    },
    changeTimezone(timezoneCode) {
      this.submissionStates.submitting = true;
      
      let payload = {
        userId: this.currentUser.id,
        data: {
          timezoneCode: timezoneCode
        }
      };
      this.$store.dispatch(UPDATE_USER_TIMEZONE, payload)
        .then(() => {
          window.location.reload();
          this.submissionStates.submitting = false;
        });
    },
    changeTimezoneSetting() {
      this.submissionStates.submitting = true;
      var newOffset = null;

      if (this.form.system_timezone_setting_id == 1) {
        newOffset = moment().format("ZZ");
      } else if (this.form.system_timezone_setting_id == 2) {
        if (this.projectInfo.systemTimezone != null) {
          newOffset = this.projectInfo.systemTimezone.offset;
        } else {
          newOffset = moment().format("ZZ");
        }
      } else {
        newOffset = this.currentUser.systemTimezone.offset;
      }
      
      let payload = {
        userId: this.currentUser.id,
        data: {
          timezoneSetting: this.form.system_timezone_setting_id
        }
      };
      this.$store.dispatch(UPDATE_USER_TIMEZONE_SETTING, payload)
        .then(() => {
          if (this.computedCurrentOffset != newOffset) {
            window.location.reload();
          };
          this.submissionStates.submitting = false;
        });
    },
    isActiveTimezone(current) {
      return this.currentUser.systemTimezone.id === current;
    },
    getTimezones: function() {
      this.$store.dispatch(POPULATE_TIMEZONES, false).catch(() => {
        this.error.active = true;
        this.error.message =
          "We're having some issues retrieving the timezones list, please check back later or contact the helpdesk";
      });
    }
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "projectInfo",
      "systemTimezones"
    ]),
    computedCurrentOffset() {
      if (this.currentUser.system_timezone_setting_id == 1) {
        return moment().format("ZZ");
      } else if (this.currentUser.system_timezone_setting_id == 2) {
        if (this.projectInfo.systemTimezone != null) {
          return this.projectInfo.systemTimezone.offset;
        } else { 
          return moment().format("ZZ");
        }
      } else {
        return this.currentUser.systemTimezone.offset;
      }
    }
  }
};
</script>
