<template>
  <span
    class="label label-pill label-inline ml-3"
    :class="computedProjectStatus.state"
    style="position:relative; top:-1px"
  >
    {{ computedProjectStatus.text }}
  </span>
</template>

<script>
export default {
  name: "ProjectStageLabel",

  props: {
    project: { type: Object, required: true }
  },

  computed: {
    computedProjectStatus: function() {
      let projectStage = {};

      if (this.project.generating == 1) {
        projectStage = {state: 'label-secondary', text: "Generating"}
      } else {
        switch (this.project.system_project_stage_id) {
          case 1:
            projectStage = {state: 'label-light-warning', text: 'Draft'}
            break;
          case 2:
            projectStage = {state: 'label-light-success', text: 'Recruitment'}
            break;
          case 3:
            projectStage = {state: 'label-light-success', text: 'Active'}
            break;
          case 4:
            projectStage = {state: 'label-light-primary', text: 'Review'}
            break;
          case 5:
            projectStage = {state: 'label-light-danger', text: 'Closed'}
            break;
          case 6:
            projectStage = {state: 'label-light-danger', text: 'Erased'}
            break;
        }
      }


      return projectStage;
    }
  }
};
</script>
