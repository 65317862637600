<template>
  <ul class="navi navi-hover" style="height:300px; overflow-y:auto">
    <div v-slimscroll="sc_options">
      <template v-for="(item, i) in systemLanguages">
        <li
          class="navi-item"
          :class="{ 'navi-item-active': isActiveLanguage(item.id) }"
          :key="i"
        >
          <a
            href="javascript:void(0)"
            class="navi-link px-2"
            v-bind:data-lang="item.i18n_key"
            @click="changeLanguage(item.code)"
          >
            <span class="symbol symbol-20 mr-3">
              <img :src="getLanguageFlag(item.flag_icon)" alt="" />
            </span>
            <span class="navi-text">{{ item.name }}</span>
          </a>
        </li>
      </template>
    </div>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { POPULATE_USER_LANGUAGE } from "../vuex/i18n.module";

export default {
  name: "DropdownLanguage",

  data() {
    return {
      sc_options:{
        height: "286.5px"
      }
    }
  },

  methods: {
    changeLanguage(languageCode) {
      let payload = {
        userId: this.currentUser.id,
        name: this.currentUser.calculatedName,
        display_name: this.currentUser.display_name,
        langaugeCode: languageCode
      };
      this.$store.dispatch(POPULATE_USER_LANGUAGE, payload)
        .then(() => {
          window.location.reload();
        });
    },
    isActiveLanguage(current) {
      return this.userLanguage.id === current;
    },
    getLanguageFlag(flag_icon) {
      return "media/svg/flags/" + flag_icon;
    }
  },

  computed: {
    ...mapGetters(["systemLanguages", "userLanguage", "currentUser"]),
  }
};
</script>
