<template>
  <div>
    <b-dropdown
      size="sm"
      variant="link"
      offset="-150"
      toggle-class="btn btn-icon btn-hover-transparent-primary d-flex align-items-center btn-lg px-md-2"
      v-b-tooltip.hover.bottom
      :title="$t('TOOLBAR.TIMEZONE.TOOLTIP')"
      no-caret
      dropdown
    >
      <template v-slot:button-content>
        <span class="svg-icon svg-icon-xl">
          <inline-svg src="media/svg/icons/Home/Clock.svg"/>
        </span>
      </template>
      <b-dropdown-text
        tag="div"
        class="toolbar-dropdown"
        :class="currentUser.system_timezone_setting_id == 3 ? 'min-w-md-350px' : 'min-w-md-250px'"
        style="max-height:450px"
      >
        <div class="mb-5 font-weight-bold"> {{ $t('FORMS.INPUTS.SYSTEM_TIMEZONE_SETTINGS') }} </div>
        <KTDropdownTimezone></KTDropdownTimezone>
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTDropdownTimezone from "@/view/layout/extras/dropdown/DropdownTimezone.vue";

export default {
  name: "TimezoneSelection",

  components: {
    KTDropdownTimezone
  },

  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>
