<template>
  <!-- begin:: Footer -->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div
        id="together_authentication_footer_brand"
        class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2 font-size-sm"
      >
        Powered by Toluna Start
      </div>
      <div
        id="together_authentication_footer_links"
        class="d-flex order-1 order-sm-2 my-2"
      >
        <a href="https://www.tolunastart.com/terms-and-conditions" target="blank" class="text-dark-75 text-hover-primary ml-4">{{ $t('NAVIGATION.FOOTER.T&C') }}</a>
        <a href="https://www.tolunastart.com/privacy-policy" target="blank" class="text-dark-75 text-hover-primary ml-4">{{ $t('NAVIGATION.FOOTER.PRIVACY') }}</a>
        <a href="javascript:void(0)" class="text-dark-75 text-hover-primary ml-4">{{ versionString }}</a>
      </div>
    </div>
    <!--end::Container-->

  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig","version"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },

    versionString() {
      return "v" + this.version;
    }
  }
};
</script>
