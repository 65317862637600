<template>
  <div class="p-0 max-h-500px" style="overflow: auto">
    <template v-for="(project, p) in calculatedProjects">
      <ProjectItem
        :key="p"
        :project="project"
      />
    </template>

    <div v-if="calculatedProjects.length == 0" class="text-center py-3">
      <span>No Projects</span>
    </div>
    
  </div>
</template>

<script>
import ProjectItem from "./ProjectItem.vue";

export default {
  name: "ProjectSelectionProjectList",

  props: {
    calculatedProjects: { type: Array, required: true }
  },

  components: {
    ProjectItem
  }
}
</script>