<template>
  <div id="kt_quick_panel" ref="kt_quick_panel">
    <!--begin::Navigation-->
    <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-2x">
      <li class="nav-item" @click="tab = 1">
        <a
          class="nav-link"
          :class="{ active: tab == 1 }"
          data-toggle="tab"
          href="javascript:void(0)"
        >
          {{ $t("NOTIFICATIONS.TEXT.UNREAD") }}
          <span
            v-if="unreadNotifications.length > 0"
            class="label label-danger label-sm ml-3"
            style="margin-top: 1px"
          >
            {{ unreadNotifications.length }}
          </span>
        </a>
      </li>
      <li class="nav-item" @click="tab = 2">
        <a
          class="nav-link"
          :class="{ active: tab == 2 }"
          data-toggle="tab"
          href="javascript:void(0)"
        >
          {{ $t("NOTIFICATIONS.TEXT.READ") }}
        </a>
      </li>
    </ul>
    <!--end::Navigation-->

    <!--begin::Unread Notifications-->
    <div v-if="tab == 1" style="height: 100%">
      <vuescroll v-if="unreadNotifications.length > 0" style="height: 100%">
        <div class="navi navi-hover pt-5">
          <template v-for="(notification, i) in unreadNotifications">
            <Notification
              :key="i"
              :notification="notification"
              :processing="processing"
            >
            </Notification>
          </template>
        </div>
      </vuescroll>
      <b-alert
        v-if="unreadNotifications.length == 0"
        show
        variant="light"
        class="mt-10"
      >
        {{ $t("NOTIFICATIONS.PLACEHOLDER.NO_UNREAD") }}
      </b-alert>
    </div>
    <!--end::Unread Notifications-->

    <!--begin::read Notifications-->
    <div v-if="tab == 2" style="height: 100%">
      <vuescroll v-if="readNotifications.length > 0" style="height: 100%">
        <div class="navi navi-hover pt-5">
          <template v-for="(notification, i) in readNotifications">
            <Notification
              :key="i"
              :notification="notification"
              :processing="processing"
            >
            </Notification>
          </template>
        </div>
      </vuescroll>
      <b-alert
        v-if="readNotifications.length == 0"
        show
        variant="light"
        class="mt-10"
      >
        {{ $t("NOTIFICATIONS.PLACEHOLDER.NO_READ") }}
      </b-alert>
    </div>
    <!--end::read Notifications-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vuescroll from "vuescroll";
import Notification from "./Notification.vue";

export default {
  name: "NotificationContainer",

  props: {
    readNotifications: { type: Array, required: true },
    unreadNotifications: { type: Array, required: true },
    processing: { type: Boolean, required: true },
  },

  data() {
    return {
      tab: 1,
    };
  },

  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs["kt_quick_panel"]);
  },
  components: {
    vuescroll,
    Notification,
  },

  computed: {
    ...mapGetters(["notificationList", "notificationErrors"]),
  },
};
</script>
