<template>
  <div>
    <a
      href="javascript:void(0)"
      class="btn btn-icon btn-clean btn-lg px-md-2"
      id="notification_toggle"
      v-b-tooltip.hover.bottom
      :title="$t('NOTIFICATIONS.TOOLTIP.TEXT')"
    >
      <span class="svg-icon svg-icon-2x">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <path
              d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z"
              fill="#000000"
            />
            <circle
              class="notification_hint"
              :class="{ active: unreadNotifications.length > 0 }"
              opacity="0.3"
              cx="18.5"
              cy="5.5"
              r="2.5"
            />
          </g>
        </svg>
      </span>
    </a>

    <div
      id="notification_container"
      ref="notification_container"
      class="offcanvas offcanvas-right p-7"
    >
      <NotificationContainer
        style="height: 90%"
        :readNotifications="readNotifications"
        :unreadNotifications="unreadNotifications"
        :processing="processing"
      >
      </NotificationContainer>

      <div
        v-if="notificationList.length > 0"
        class="d-flex justify-content-center pt-7 w-75 position-absolute"
        style="margin-left: 7.5%; bottom: 15px"
      >
        <div class="col-6 pl-0 pr-2">
          <button
            class="btn btn-sm btn-outline-warning w-100 px-2"
            @click="readAll"
            v-b-tooltip.hover.top
            :title="$t('NOTIFICATIONS.ACTIONS.READ_ALL_EXPANDED')"
          >
            {{ $t("NOTIFICATIONS.ACTIONS.READ_ALL") }}
          </button>
        </div>
        <div class="col-6 pl-2 pr-0">
          <button
            class="btn btn-sm btn-outline-danger w-100 px-2"
            @click="deleteAll"
            v-b-tooltip.hover.top
            :title="$t('NOTIFICATIONS.ACTIONS.DELETE_ALL_EXPANDED')"
          >
            {{ $t("NOTIFICATIONS.ACTIONS.DELETE_ALL") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  POPULATE_USER_NOTIFICATIONS_LIST,
  UPDATE_ALL_USER_NOTIFICATIONS,
  DELETE_ALL_USER_NOTIFICATIONS,
} from "@/modules/together-helpers/components/generic-features/notifications/vuex/notifications.module.js";

import NotificationOffcanvas from "@/modules/together-helpers/components/generic-features/notifications/js/notifications.js";
import NotificationContainer from "./notifications/components/NotificationContainer.vue";

export default {
  name: "Notifications",

  data() {
    return {
      pageLoader: {
        componentsRequired: 1,
        componentsCompleted: 0,
      },
      error: {
        active: false,
        message: "",
      },
      processing: false,
      offCanvas: false,
    };
  },

  components: {
    NotificationContainer,
  },
  watch: {
    $route(to, from) {
      // notification redirected close panel
      this.offCanvas.overlay.click();
    }
  },
  mounted() {
    const offCanvasObject = NotificationOffcanvas.init(
      this.$refs["notification_container"]
    );
    this.offCanvas = offCanvasObject;
    this.fetchNotifications();
  },

  methods: {
    fetchNotifications: function () {
      let payload = {
        userId: this.currentUser.id,
        params: {
          projectId: this.projectInfo.id,
        }
      };
      this.$store
        .dispatch(POPULATE_USER_NOTIFICATIONS_LIST, payload)
        .then((res) => {
          let unreadNotifications = res.filter(function (notification) {
            return notification.read_at == null;
          });
          if (unreadNotifications.length > 0) {
            this.$root.$bvToast.toast(
              `${this.$t("TOASTS.NOTIFICATION.MESSAGE")}`,
              {
                title: `${this.$t("TOASTS.NOTIFICATION.TITLE")}`,
                autoHideDelay: 10000,
                variant: "success",
              }
            );
          }
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the user's notifications, please check back later or contact the helpdesk";
        });
    },
    readAll: function () {
      if (this.processing) {
        return;
      }

      this.processing = true;

      let payload = {
        userId: this.currentUser.id,
      };

      this.$store
        .dispatch(UPDATE_ALL_USER_NOTIFICATIONS, payload)
        .finally(() => {
          this.processing = false;
        });
    },
    deleteAll: function () {
      if (this.processing) {
        return;
      }

      this.processing = true;

      let payload = {
        userId: this.currentUser.id,
      };

      this.$store
        .dispatch(DELETE_ALL_USER_NOTIFICATIONS, payload)
        .finally(() => {
          this.processing = false;
        });
    },
  },

  computed: {
    ...mapGetters([
      "notificationList",
      "projectInfo",
      "notificationErrors",
      "currentUser",
    ]),
    unreadNotifications: function () {
      return this.notificationList.filter(function (obj) {
        return obj.read_at == null;
      });
    },
    readNotifications: function () {
      return this.notificationList.filter(function (obj) {
        return obj.read_at != null;
      });
    },
  },
};
</script>