<template>
  <div>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="btn btn-icon btn-hover-transparent-primary d-flex align-items-center btn-lg px-md-2 w-md-auto"
      v-b-tooltip.hover.bottom
      :title="$t('TOOLBAR.USER.TOOLTIP')"
      no-caret
      dropdown
    >
      <template v-slot:button-content>
        <span
          class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
          style="text-decoration: none"
        >{{ $t('TOOLBAR.USER.HI') }},
        </span>
        <span
          class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
          style="text-decoration: none"
        >
          {{ currentUser.display_name }}
        </span>
        <UserThumbnail :user="currentUser"></UserThumbnail>
      </template>
      <b-dropdown-text tag="div" class="toolbar-dropdown min-w-md-200px">
        <p class="text-center w-100 font-size-lg pb-1 mb-1">
          {{ $t('TOOLBAR.USER.HELLO') }}, {{ currentUser.display_name }}
        </p>
        <div class="separator separator-solid mb-1"></div>
        <button
          type="button"
          class="btn btn-secondary w-100 my-2"
          @click="onLogout()"
        >
          {{ $t('TOOLBAR.USER.LOGOUT') }}
        </button>
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
import { LOGOUT } from "@/modules/together-authentication/store/auth.module";
import { mapGetters } from "vuex";

import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";

export default {

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  
  components: {
    UserThumbnail
  },

  computed: {
    ...mapGetters(["currentUser"]),
  }

};
</script>
