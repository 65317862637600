<template>
  <!--begin::Nav-->
  <ul class="menu-nav" role="tablist">

    <template v-for="(item, i) in relativeItems">

      <li
        v-if="item.section != null && (item.identifier != 'ProjectReview' || (item.identifier == 'ProjectReview' && !currentUser.menu_items.includes('!SECTION_REPORTS') ))"
        :key="'section-' + i"
        class="menu-section"  
      >
        <h4 class="menu-text">{{ item.section }}</h4>
        <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
      </li>

      <template v-for="(child, c) in item.children">
        <li
          v-if="child.children.length == 0 && userCanAccess(child.identifier)"
          :key="'child-' + i + '-' + c"
          class="menu-item"
        >
          <router-link
            v-if="child.redirect == false"
            tag="a"
            class="menu-link px-5"
            :to="child.link"
          >
            <span class="svg-icon svg-icon-lg mr-4">
              <inline-svg :src="child.icon" />
            </span>
            <span class="menu-text">{{ child.text }}</span>
          </router-link>

          <a v-else target="_blank" :href="child.link" class="menu-link px-5">
            <span class="svg-icon svg-icon-lg mr-4">
              <inline-svg :src="child.icon" />
            </span>
            <span class="menu-text">{{ child.text }}</span> 
          </a>
        </li>

        <li
          v-else-if="child.children.length > 0 && userCanAccess(child.identifier)"
          :key="'child-' + i + '-' + c"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
        >
          <a href="#" class="menu-link menu-toggle px-5">
            <span class="svg-icon svg-icon-lg mr-4">
              <inline-svg :src="child.icon" />
            </span>
            <span class="menu-text">{{ child.text }}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link">
                  <span class="menu-text">{{ child.text }}</span>
                </span>
              </li>

              <template v-for="(subchild, s) in child.children">
                <li
                  v-if="userCanAccess(subchild.identifier)"
                  :key="'child-' + i + '-' + s"
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                >
                  <router-link
                    v-if="subchild.redirect == false"
                    tag="a"
                    class="menu-link"
                    :to="subchild.link"
                  >
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">{{ subchild.text }}</span>
                  </router-link>

                  <a v-else :href="subchild.link" class="menu-link">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">{{ subchild.text }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </li>
      </template>

    </template>

  </ul>
  <!--end::Nav-->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Menu",

  methods: {
    hasActiveChildren: function(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    userCanAccess: function(identifier) {
      return (this.currentUser.menu_items.includes(identifier)) ? true : false;
    }
  },

  computed: {
    ...mapGetters(["menuType", "projectInfo", "currentUser"]),

    relativeItems: function() {
      switch (this.menuType) {
        case 'root':
          return this.rootItems;
        case 'client':
          return this.clientItems;
        case 'project':
          return this.projectItems;
        default:
          return [];
      }
    },

    rootItems: function() {
      return [
        {
          section: null,
          identifier: "AdminMenu",
          children: [
            {
              link: { name: 'view_clients' },
              redirect: false,
              text: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`,
              icon: "media/svg/icons/Layout/Layout-4-blocks.svg",
              children: [],
              identifier: 'AdminClients'
            },
            { 
              link: { name: 'view_users' },
              redirect: false,
              text: `${this.$t("NAVIGATION.ROOT_MENU.USERS")}`,
              icon: "media/svg/icons/General/User.svg",
              children: [],
              identifier: 'AdminSysAdmins'
            },
            { 
              link: { name: 'view_system_templates' },
              redirect: false,
              text: `${this.$t("NAVIGATION.ROOT_MENU.TEMPLATES")}`,
              icon: "media/svg/icons/Files/Folder-star.svg",
              children: [],
              identifier: 'AdminSysTemplates'
            }
          ]
        }
      ];
    },

    clientItems: function() {
      return [
        {
          section: null,
          identifier: "ClientMenu",
          children: [
            {
              link: { name: 'client_projects' },
              redirect: false,
              text: `${this.$t("NAVIGATION.CLIENT_MENU.PROJECTS")}`,
              icon: "media/svg/icons/Files/Folder.svg",
              children: [],
              identifier: 'AdminProjects'
            },
            {
              link: { name: 'edit_client' },
              redirect: false,
              text: `${this.$t("NAVIGATION.CLIENT_MENU.EDIT")}`,
              icon: "media/svg/icons/Design/Edit.svg",
              children: [],
              identifier: 'AdminClientEdit'
            },
            {
              link: { name: 'client_users' },
              redirect: false,
              text: `${this.$t("NAVIGATION.CLIENT_MENU.USERS")}`,
              icon: "media/svg/icons/General/User.svg",
              children: [],
              identifier: 'AdminClientUsers'
            },
            {
              link: { name: 'view_project_templates' },
              redirect: false,
              text: `${this.$t("NAVIGATION.CLIENT_MENU.TEMPLATES")}`,
              icon: "media/svg/icons/Files/Folder-star.svg",
              children: [],
              identifier: 'AdminProjectTemplates'
            }
          ]
        }
      ];
    },

    projectItems: function() {
      return [
        {
          section: null,
          identifier: "ProjectManage",
          children: [
            {
              link: { name: 'view_project' },
              redirect: false,
              text: `${this.$t("NAVIGATION.PROJECT_MENU.DASHBOARD")}`,
              icon: "media/svg/icons/Devices/Diagnostics.svg",
              children: [],
              identifier: 'AdminDashboard'
            },
            {
              link: { name: 'edit_project', params: { id: this.projectInfo.id } },
              redirect: false,
              text: `${this.$t("NAVIGATION.PROJECT_MENU.EDIT")}`,
              icon: "media/svg/icons/Design/Edit.svg",
              children: [],
              identifier: 'AdminEditProject'
            },
            {
              link: { name: 'edit_template', params: { id: this.projectInfo.project_template_id } },
              redirect: false,
              text: `${this.$t("NAVIGATION.TEMPLATE_MENU.EDIT")}`,
              icon: "media/svg/icons/Design/Edit.svg",
              children: [],
              identifier: 'AdminEditTemplate'
            }
          ]
        },
        {
          section: `${this.$t("NAVIGATION.PROJECT_MENU.SETUP")}`,
          identifier: "ProjectSetup",
          children: [
            {
              link: { name: 'project_activities' },
              redirect: false,
              text: `${this.$t("NAVIGATION.PROJECT_MENU.ACTIVITIES")}`,
              icon: "media/svg/icons/Communication/Group-chat.svg",
              children: [],
              identifier: 'AdminActivities'
            },
            {
              link: null,
              redirect: false,
              text: `${this.$t("NAVIGATION.PROJECT_MENU.USERS")}`,
              icon: "media/svg/icons/General/User.svg",
              children: [
                { link: { name: 'project_users' }, redirect: false, text: `${this.$t("NAVIGATION.PROJECT_MENU.MANAGE")}`, identifier: 'AdminProjectUsers' },
                { link: { name: 'setup_segmentations' }, redirect: false, text: `${this.$t("SETUP.NAV.SEGMENTATION")}`, identifier: 'AdminSegmentations' },
                { link: { name: 'setup_basic_profiling' }, redirect: false, text: `${this.$t("SETUP.NAV.BASIC_PROFILING")}`, identifier: 'AdminBasicProfiling' },
                { link: { name: 'setup_extended_profiling' }, redirect: false, text: `${this.$t("SETUP.NAV.EXTENDED_PROFILING")}`, identifier: 'AdminExtendedProfiling' },
              ],
              identifier: 'AdminUserSection'
            },     
            {
              link: null,
              redirect: false,
              text: `${this.$t("NAVIGATION.PROJECT_MENU.STYLING")}`,
              icon: "media/svg/icons/Design/Edit.svg",
              children: [
                { link: { name: 'setup_theme' }, redirect: false, text: `${this.$t("SETUP.NAV.THEME")}`, identifier: 'AdminTheme' },
                { link: { name: 'setup_rooms' }, redirect: false, text: `${this.$t("SETUP.NAV.ROOMS")}`, identifier: 'AdminRooms' },
                { link: { name: 'setup_widgets' }, redirect: false, text: `${this.$t("SETUP.NAV.WIDGETS")}`, identifier: 'AdminWidgets' },
                { link: { name: 'pages_summary' }, redirect: false, text: `${this.$t("NAVIGATION.PROJECT_MENU.PAGES")}`, identifier: 'AdminPages' }
              ],
              identifier: 'AdminStyleSection'
            },
            {
              link: { name: 'setup_community_options' },
              redirect: false,
              text: `${this.$t("SETUP.NAV.COMMUNITY_OPTIONS")}`,
              icon: "media/svg/icons/Design/Component.svg",
              children: [],
              identifier: 'AdminExtended'
            },
          ]
        },
        {
          section: `${this.$t("NAVIGATION.PROJECT_MENU.REVIEW")}`,
          identifier: "ProjectReview",
          children: [
            {
              link: { name: 'activity_engagement' },
              redirect: false,
              text: `${this.$t("REVIEW.NAV.ACTIVITY_ENGAGEMENT")}`,
              icon: "media/svg/icons/Communication/Group-chat.svg",
              children: [],
              identifier: 'AdminEngagement'
            },
            {
              link: { name: 'user_engagement' },
              redirect: false,
              text: `${this.$t("REVIEW.NAV.USER_ENGAGEMENT")}`,
              icon: "media/svg/icons/Communication/Group.svg",
              children: [],
              identifier: 'AdminUserEngagement'
            },
            {
              link: { name: 'stream' },
              redirect: false,
              text: `${this.$t("REVIEW.NAV.STREAM")}`,
              icon: "media/svg/icons/Shopping/Sort1.svg",
              children: [],
              identifier: 'AdminStream'
            },
            {
              link: { name: 'text_analysis' },
              redirect: false,
              text: `${this.$t("REVIEW.NAV.TEXT_ANALYSIS")}`,
              icon: "media/svg/icons/Text/Text.svg",
              children: [],
              identifier: 'AdminTextAnalysis'
            },
            {
              link: { name: 'project_reports' },
              redirect: false,
              text: `${this.$t("NAVIGATION.PROJECT_MENU.REPORTS")}`,
              icon: "media/svg/icons/Communication/Archive.svg",
              children: [],
              identifier: 'AdminReports'
            },
          ]
        },
        {
          section: `${this.$t("NAVIGATION.PROJECT_MENU.PARTICIPANTS")}`,
          identifier: "ProjectParticipants",
          children: [
            {
              link: { name: 'communicate_summary' },
              redirect: false,
              text: `${this.$t("NAVIGATION.PROJECT_MENU.COMMUNICATE")}`,
              icon: "media/svg/icons/Communication/Mail.svg",
              children: [],
              identifier: 'AdminCommunicate'
            },
            {
              link: this.participantLink,
              redirect: true,
              text: `${this.$t("NAVIGATION.PROJECT_MENU.PARTICIPANT")}`,
              icon: "media/svg/icons/Devices/Tablet.svg",
              children: [],
              identifier: 'AdminParticipantTheme'
            }
          ]
        },
      ];
    },

    participantLink: function() {
      return this.projectInfo.inputLink;
    }
  }
};
</script>
