var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('InputGenericRadioOption',{staticClass:"col-xl-12 px-5 mb-0",attrs:{"model":_vm.$v.form.system_timezone_setting_id,"submissionStates":_vm.submissionStates,"id":"input-system-timezone-setting","displayLabel":false,"error":_vm.$t('FORMS.ERROR.SYSTEM_TIMEZONE_SETTING'),"options":[
        {
          value: 1,
          text: _vm.$t('FORMS.INPUTS.TIMEZONE_SETTING_LOCAL'),
          tooltip: _vm.$t('TIMEZONE.TOOLTIPS.LOCAL')
        },
        {
          value: 2,
          text: _vm.$t('FORMS.INPUTS.TIMEZONE_SETTING_PROJECT'),
          tooltip: _vm.$t('TIMEZONE.TOOLTIPS.PROJECT')
        },
        {
          value: 3,
          text: _vm.$t('FORMS.INPUTS.TIMEZONE_SETTING_USER'),
          tooltip: _vm.$t('TIMEZONE.TOOLTIPS.USER')
        }
      ]},on:{"radio_option_changed":function($event){return _vm.changeTimezoneSetting()}}})],1),(_vm.form.system_timezone_setting_id == 3)?_c('ul',{staticClass:"navi navi-hover",staticStyle:{"height":"300px","overflow-y":"auto"}},[_vm._l((_vm.systemTimezones),function(item,i){return [_c('li',{key:i,staticClass:"navi-item",class:{ 'navi-item-active bg-secondary': _vm.isActiveTimezone(item.id) }},[_c('a',{staticClass:"navi-link px-2",attrs:{"href":"javascript:void(0)","data-time":item.id},on:{"click":function($event){return _vm.changeTimezone(item.code)}}},[_c('span',{staticClass:"navi-text",staticStyle:{"width":"240px"}},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"navi-text",staticStyle:{"width":"50px"}},[_vm._v(_vm._s(item.offset))])])])]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }