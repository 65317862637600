<template>
  <div
    v-bind:class="{ 'quick-search-has-result': hasResults }"
    class="quick-search quick-search-dropdown quick-search-result-compact"
    id="kt_quick_search_dropdown"
  >
    <form method="get" class="quick-search-form" @submit.prevent="onSearch">
      <div
        class="input-group spinner-input spinner-sm spinner-brand spinner-right"
        v-bind:class="{ spinner: loading }"
      >
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="flaticon2-search-1"></i>
          </span>
        </div>
        <input
          v-on:keyup="onSearch"
          type="text"
          class="form-control quick-search-input"
          placeholder="Search..."
          v-model="search"
          autofocus
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <a
              href="javascript:void(0)"
              v-on:click="reset"
            >
              <i
                class="fas fa-times icon-xs"
                v-if="hasResults"
              ></i>
            </a>
          </span>
        </div>
      </div>
    </form>

    <KTSearchResult
      :data="generalSearchResults"
      :loading="loading"
      :hasResults="hasResults"
    >
    </KTSearchResult>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTSearchResult from "@/view/layout/extras/dropdown/SearchResult.vue";
import { POPULATE_GENERAL_SEARCH } from "@/core/services/store/system/general_search.module";
import { CLEAR_GENERAL_SEARCH } from "@/core/services/store/system/general_search.module";

export default {
  name: "KTSearchDefault",
  components: { KTSearchResult },
  data() {
    return {
      search: "",
      data: [],
      loading: false
    };
  },
  methods: {
    onSearch() {
      if (this.search.length >= 2) {
        let search = this.search;
        setTimeout(() => {
          let payload = {
            params: {
              search: this.search
            }
          };
          if (search == this.search && !this.loading) {
            this.loading = true;
            this.$store
              .dispatch(POPULATE_GENERAL_SEARCH, payload)
              .then(() => {
                this.loading = false;
              })
              .catch(() => {
                this.error.active = true;
                this.error.message =
                  "We're having some issues retrieving the search results, please check back later or contact the helpdesk";
              })
              .finally(() => {
                this.loading = false;
              })
          }
        },1500);
      } else if (this.search.length == 0) {
        this.reset();
      }
    },
    /**
     * Reset search data
     */
    reset() {
      this.search = "";
      this.$store.dispatch(CLEAR_GENERAL_SEARCH);
    }
  },
  computed: {
    ...mapGetters([
      "generalSearchResults"
    ]),
    hasResults: function() {
      return (Object.keys(this.generalSearchResults).length > 0) ? true : false;
    }
  }
};
</script>
