<template>
  <perfect-scrollbar
    class="quick-search-wrapper scroll"
    style="max-height: 40vh; position: relative;"
    v-bind:options="{ suppressScrollX: true }"
  >
    <div class="quick-search-result">
      <!--begin::Message-->
      <div v-if="!loading && !hasAnyResults" class="text-muted">
        {{ $t('REVIEW.STREAM.NO_SEARCH_PLACEHOLDER') }}
      </div>
      <!--end::Message-->

      <div v-if="hasResults">

        <!--begin::Clients-->
        <div
          v-if="data.Clients.length > 0"
          class="font-size-sm text-primary font-weight-bolder text-uppercase mb-2"
        >
          {{ $t('NAVIGATION.ROOT_MENU.CLIENTS') }}
        </div>
        <template v-for="(client, c) in data.Clients">
          <div
            :key="'client'+c"
            class="d-flex align-items-center flex-grow-1 mb-0"
          >
            <div class="symbol symbol-30 flex-shrink-0">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
              </span>
            </div>
            <div class="d-flex flex-column ml-3 mt-2 mb-2">
              <a :href="clientUrl(client)" class="font-weight-bold text-dark text-hover-primary">
                {{ client.name }}
              </a>
              <div
                class="font-size-sm font-weight-bold text-muted"
              >
                {{ client.key }}
              </div>
            </div>
          </div>
        </template>
        <!--end::Clients-->

        <!--begin::Projects-->
        <div
          v-if="data.Projects.length > 0"
          class="font-size-sm text-warning font-weight-bolder text-uppercase mb-2 mt-5"
        >
          {{ $t('NAVIGATION.CLIENT_MENU.PROJECTS') }}
        </div>
        <template v-for="(project, p) in data.Projects">
          <div
            :key="'project'+p"
            class="d-flex align-items-center flex-grow-1 mb-0"
          >
            <div class="symbol symbol-30 flex-shrink-0">
              <span class="svg-icon svg-icon-md svg-icon-warning">
                <inline-svg src="media/svg/icons/Files/Folder.svg" />
              </span>
            </div>
            <div class="d-flex flex-column ml-3 mt-2 mb-2">
              <a :href="projectUrl(project,project.client,'view')" class="font-weight-bold text-dark text-hover-primary">
                {{ project.name }}
              </a>
              <div
                class="font-size-sm font-weight-bold text-muted"
              >
                {{ project.key }}
              </div>
            </div>
          </div>
        </template>
        <!--end::Projects-->

        <!--begin::Users-->
        <div
          v-if="data.Users.length > 0"
          class="font-size-sm text-success font-weight-bolder text-uppercase mb-2 mt-5"
        >
          {{ $t('NAVIGATION.GENERAL.USERS') }}
        </div>
        <template v-for="(user, u) in data.Users">
          <div
            :key="'user'+u"
            class="d-flex align-items-center flex-grow-1 mb-0"
          >
            <div class="symbol symbol-30 flex-shrink-0">
              <span class="svg-icon svg-icon-md svg-icon-success">
                <inline-svg src="media/svg/icons/General/User.svg" />
              </span>
            </div>
            <div class="d-flex flex-column ml-3 mt-2 mb-2">
              <a href="#" class="font-weight-bold text-dark text-hover-success">
                {{ user.display_name }}
              </a>
              <div
                class="font-size-sm font-weight-bold text-muted"
              >
                {{ user.calculatedEmail }}
              </div>
            </div>
          </div>
        </template>
        <!--end::Users-->

        <!--begin::Activities-->
        <div
          v-if="data.Activities.length > 0"
          class="font-size-sm text-danger font-weight-bolder text-uppercase mb-2 mt-5"
        >
          {{ $t('NAVIGATION.PROJECT_MENU.ACTIVITIES') }}
        </div>
        <template v-for="(activity, a) in data.Activities">
          <div
            :key="'activity'+a"
            class="d-flex align-items-center flex-grow-1 mb-0"
          >
            <div class="symbol symbol-30 flex-shrink-0">
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <inline-svg src="media/svg/icons/Communication/Group-chat.svg" />
              </span>
            </div>
            <div class="d-flex flex-column ml-3 mt-2 mb-2">
              <a
                href="javascript:void(0)"
                class="font-weight-bold text-dark text-hover-danger"
                @click="applyStreamFilter({ activities: [{ id: activity.id, display: activity.title }] }, activity.client, activity.project)"
              >
                {{ activity.title }}
              </a>
              <div
                class="font-size-sm font-weight-bold text-muted"
              >
                {{ activity.activity_type.name }} || {{ activity.project.name }}
              </div>
            </div>
          </div>
        </template>
        <!--end::Activities-->

      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { POPULATE_FILTER_LIST } from "@/core/services/store/project/project_stream.module";

export default {
  name: "KTSearchResult",

  props: {
    data: { type: Object, required: true },
    loading: { type: Boolean, required: true },
    hasResults: { type: Boolean, required: true }
  },

  methods: {
    clientUrl: function(client) {
      let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
      return protocol + client.key + '.' + process.env.VUE_APP_ADMIN_URL_SUFFIX + "/#/client/projects";
    },
    projectUrl: function(project,client,location) {
      let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
      if (client.custom_domain == null) {
        return protocol + project.key.toLowerCase() + "-" + client.key + "." + process.env.VUE_APP_ADMIN_URL_SUFFIX + "/#/project/" + location;
      } else {
        return protocol + project.key.toLowerCase() + ".admin." + client.custom_domain + "/#/project/" + location;
      }
    },
    applyStreamFilter: function(filter, client, project) {
      this.$store
        .dispatch(POPULATE_FILTER_LIST, filter)
        .then(() => {
          window.location.href = this.projectUrl(project,client,'review/stream');
        })
    } 
  },

  computed: {
    hasAnyResults: function() {
      if (this.hasResults) {
        if (
          this.data.Clients.length == 0 &&
          this.data.Users.length == 0 &&
          this.data.Projects.length == 0 &&
          this.data.Activities.length == 0
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }
};
</script>
