<template>
  <div>
    <b-form-group
      :id="id+'-group'"
      :label="computedLabel"
      :label-for="id"
      :label-sr-only="!computedDisplayLabel"
      class="w-100 px-0"
      :class="{ 'mb-0 ': model.$anyError }"
    >
      <template v-for="(option, i) in options">
        <b-form-radio
          :key="i"
          v-model="model.$model"
          :name="id + '-option-' + i"
          :value="option[computedValue]"
          :disabled="submissionStates.submitting === true"
          size="lg"
          class="mb-2"
          @change="$emit('radio_option_changed')"
        >
        <p
          v-b-tooltip.hover.right
          :title="option.tooltip"
          class="mb-0"
        >
          {{ option[computedText] }}
        </p>
        </b-form-radio>
      </template>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$anyError">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "GenericRadioOption",

  props: {
    model: { type: Object, required: true },
    submissionStates: { type: Object, required: true },
    id: { type: String, required: true },
    label: { type: String, required: false },
    error: { type: String, required: true },
    options: { type: Array, required: true },
    value: { type: String, required: false },
    text: { type: String, required: false },
    displayLabel: { type: Boolean, required: false, default: true }
  },
  
  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    }
  },

  computed: {
    computedDisplayLabel: function() {
      if (this.displayLabel == undefined) {
        return true;
      } else {
        if (this.displayLabel == true) {
          return true;
        } else {
          return false;
        }
      }
    },
    computedLabel: function() {
      if (this.model.$params.hasOwnProperty('required')) {
        return this.label + "*";
      } else {
        return this.label;
      }
    },
    computedValue: function() {
      if (this.value != undefined) {
        return this.value;
      } else {
        return "value";
      }
    },
    computedTooltip: function() {
      if (this.tooltip != undefined) {
        return this.tooltip;
      } else {
        return "tooltip";
      }
    },
    computedText: function() {
      if (this.text != undefined) {
        return this.text;
      } else {
        return "text";
      }
    }
  }
}
</script>