<template>
  <!--begin::Brand-->
  <div
    class="aside-brand d-flex flex-column align-items-center flex-column-auto py-3 py-lg-6 w-100"
  >
    <!--begin::Logo-->
    <router-link to="/clients/view">
      <img :src="siteLogo" alt="Logo" style="max-height:23px !important" />
    </router-link>
    <!--end::Logo-->
  </div>
  <!--end::Brand-->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";

export default {
  name: "KTBrand",

  props: {
    window: { required: true }
  },
  
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },

    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo

      let logoObject = null;

      if (this.window.width <= 991.98 ) {
        logoObject = this.layoutConfig("self.logo-inverse");
      } else {
        logoObject = this.layoutConfig("self.logo");
      }

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    }
  }
};
</script>
