<template>
  <div>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="btn btn-icon btn-hover-transparent-primary d-flex align-items-center btn-lg px-md-2 w-md-auto"
      v-b-tooltip.hover.bottom
      :title="$t('TOOLBAR.LANGUAGE.TOOLTIP')"
      no-caret
      dropdown
    >
      <template v-slot:button-content>
        <img
          class="h-20px w-25px rounded-lg"
          :src="getLanguageFlag"
          alt=""
        />
      </template>
      <b-dropdown-text tag="div" class="toolbar-dropdown min-w-md-200px" style="max-height:320px">
        <LanguageDropdown></LanguageDropdown>
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageDropdown from "./languages/components/Dropdown";

export default {
  name: "LanguageSelection",

  components: {
    LanguageDropdown
  },

  computed: {
    ...mapGetters(["userLanguage"]),
    
    getLanguageFlag() {
      return "media/svg/flags/" + this.userLanguage.flag_icon;
    }
  }
};
</script>
