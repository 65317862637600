<template>
    <div class="col-12 px-0" @click="readNotification">
      <div class="navi-item">
        <div class="navi-link px-2">

          <!-- start::Notification Icon -->
          <a :href="notificationLink" class="navi-icon mr-2" >
            <i :class="notificationIconClass"></i>
          </a>
          <!-- end::Notification Icon -->

          <!-- start::Notification Text and Link -->
          <a :href="notificationLink" class="navi-text">
            <div class="font-weight-bold">{{ notificationData.title }}</div>
            <div 
              class="text-muted" 
              style="display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;"
            > {{ notificationData.message }} </div>
            <div class="text-muted font-size-sm">
              <GenericDatetimeDisplay
                :long="true"
                :date="notification.updated_at"
                :createdAt="true"
                :lastUpdated="false"
                :relative="true"
              >
              </GenericDatetimeDisplay>
            </div>
          </a>
          <!-- end::Notification Text and Link -->

          <!-- start::Read Notification Action -->
          <a
            v-if="notification.read_at == null"
            href="javascript:void(0)"
            class="nav-icon ml-5"
            @click="readNotification"
            v-b-tooltip.hover.left
            :title="$t('NOTIFICATIONS.ACTIONS.READ')"
          >
            <button type="button" :disabled="isProcessing" class="btn btn-icon btn-xs btn-warning">
              <i class="flaticon2-black-back-closed-envelope-shape"></i>
            </button>
          </a>
          <!-- end::Read Notification Action -->

          <!-- start::Delete Notification Action -->
          <a
            v-if="notification.read_at != null"
            href="javascript:void(0)"
            class="nav-icon ml-5"
            @click="deleteNotification"
            v-b-tooltip.hover.left
            :title="$t('NOTIFICATIONS.ACTIONS.DELETE')"
          >
            <button type="button" :disabled="isProcessing" class="btn btn-icon btn-xs btn-danger">
              <i class="flaticon2-trash"></i>
            </button>
          </a>
          <!-- end::Delete Notification Action -->
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

import {
  UPDATE_USER_NOTIFICATION,
  DELETE_USER_NOTIFICATION
} from "@/modules/together-helpers/components/generic-features/notifications/vuex/notifications.module.js";
//import { POPULATE_FILTER_LIST } from "@/core/services/store/project/project_stream.module";

export default {
  name: "Notification",

  props: {
    notification: { type: Object, required: true },
    processing: { type: Boolean, required: true }
  },

  data() {
    return {
      localProcessing: false
    }
  },

   components: {
    GenericDatetimeDisplay
  },

  methods: {
    deleteNotification: function() {
      if (this.isProcessing) {
        return;
      }

      this.localProcessing = true;

      let payload = {
        userId: this.currentUser.id,
        id: this.notification.id
      };

      this.$store.dispatch(DELETE_USER_NOTIFICATION, payload)
        .finally(() => {
          this.localProcessing = false;
        })
    },
    readNotification: function() {
      if (this.isProcessing) {
        return;
      }

      this.localProcessing = true;

      let payload = {
        userId: this.currentUser.id,
        id: this.notification.id
      };

      this.$store.dispatch(UPDATE_USER_NOTIFICATION, payload)
        .finally(() => {
          this.localProcessing = false;
        })
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    notificationLink: function() {
      if (this.notificationData.link == null) {
        return null;
      } else {
        let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
        
        let suffixLookup = {
          INPUT: process.env.VUE_APP_INPUT_URL_SUFFIX,
          ADMIN: process.env.VUE_APP_ADMIN_URL_SUFFIX
        };

        let suffix = null;

        if (this.notificationData.link.node != null) {
          suffix = this.notificationData.link.node.toUpperCase();
        } else {
          if (this.currentUser.extended_relation_to_project != null) {
            suffix = (this.currentUser.extended_relation_to_project == 'Subscribed Participant') ? 'INPUT' : 'ADMIN';
          } else {
            suffix = process.env.VUE_APP_SUBMODULE_IDENTIFIER;
          }
        }

        return protocol + this.notificationData.link.prefix.toLowerCase() + "." + suffixLookup[suffix] + this.notificationData.link.slug;
      }
    },
    notificationData: function() {
      return JSON.parse(this.notification.data);
    },
    notificationIconClass: function() {
      if (this.notificationData.link != null) {
        if (this.notificationData.link.scope == "project") {
          return "flaticon2-folder text-primary";
        } else if (this.notificationData.link.scope == "review") {
          return "flaticon2-pie-chart-1 text-warning";
        }
        else {
          return "";
        }
      } else {
        return "flaticon2-notification text-success";
      }
    },
    isProcessing: function() {
      if (this.processing || this.localProcessing) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>
