<template>
  <div class="px-3 pt-3">
    <template
      v-for="(category, c) in [ { label: 'Project Roles', options: calculatedRoles }, { label: 'Registration Status', options: calculatedRegistrationStatus } ]"
    >
      <div class="pb-2 mb-5" :class="{ 'border-bottom' : c == 0 }" :key="c">
        <h6 style="font-size: 1rem">{{ category.label }}</h6>
        <template v-for="(role, r) in category.options">
          <div
            class="custom-control custom-switch"
            :key="`${c}-${r}`"
          >
            <input
              :id="`setting-switch-${c}-${r}`"
              type="checkbox"
              class="custom-control-input"
              value="1"
              :checked="filter[role.key] == 1"
              @change="$emit('on-setting-change', { option: role.key, value: $event.target.checked })"
            >
            <label
              class="custom-control-label"
              :for="`setting-switch-${c}-${r}`"
            >
              {{ role.label }}
            </label>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ProjectSelectionFilterOptions",

  props: {
    filter: { type: Object, required: true }
  },

  computed: {
    calculatedRoles: function() {
      return [
        { key: "community_manager", label: "Community Manager", default: true },
        { key: "observer", label: "Observer", default: true },
        { key: "participant", label: "Participant", default: true }
      ]
    },
    calculatedRegistrationStatus: function() {
      return [
        { key: "subscribed", label: "Subscribed", default: true },
        { key: "invited", label: "Invited", default: true }
      ]
    }
  }
}
</script>