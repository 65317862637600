<template>
  <div
    class="d-flex flex-column flex-root"
    v-if="isAuthenticated"
  >
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <!-- start:: PageLoader -->
    <Loader v-if="!loadingComplete" v-bind:logo="loaderLogo"></Loader>
    <!-- end:: PageLoader -->

    <!-- begin::Body -->
    <div v-if="loadingComplete" class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Content -->
        <div id="kt_content" class="content d-flex flex-column flex-column-fluid py-4">

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              class="px-4"
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
          <!-- end:: Content Body -->

        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageLoader from "@/core/classes/PageLoader.js";

//Components
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/modules/together-helpers/components/application-components/Loader.vue";

//Vuex
import {
  ADD_BODY_CLASSNAME,
  //REMOVE_BODY_CLASSNAME
} from "@/core/services/store/system/htmlclass.module.js";

import { POPULATE_DEFAULT_LANGUAGE } from "@/modules/together-helpers/components/generic-features/languages/vuex/i18n.module";;

import {
  POPULATE_ACTIVITY_NOTIFICATIONS,
  POPULATE_ACTIVITY_SETTINGS,
  POPULATE_ACTIVITY_QUESTION_SETTINGS,
  POPULATE_ACTIVITY_STAGES,
  POPULATE_ACTIVITY_TYPES,
  POPULATE_ADMIN_ROLES,
  POPULATE_ANSWER_TYPES,
  POPULATE_CLIENT_ROLES,
  POPULATE_COUNTRIES,
  POPULATE_GENDERS,
  POPULATE_LANGUAGES,
  POPULATE_MARKUP_PIN_TYPES,
  POPULATE_NOTIFY_METHODS,
  POPULATE_PAGE_TYPES,
  POPULATE_PROFILE_QUESTIONS,
  POPULATE_PROJECT_ROLES,
  POPULATE_PROJECT_STAGES,
  POPULATE_PROJECT_USER_SAMPLES,
  POPULATE_PROJECT_TEMPLATE_TYPES,
  POPULATE_ROOM_SETUPS,
  POPULATE_SEGMENT_CLASSES,
  POPULATE_SOCIAL_BIAS,
  POPULATE_SOCIAL_SETTING,
  POPULATE_TIMEZONES,
  POPULATE_USER_REGISTER_TYPE,
  POPULATE_WIDGET_TYPES
} from "@/core/services/store/system/system.module.js";

import { POPULATE_VERSION } from "@/modules/together-helpers/vuex/system/version.module";

export default {
  name: "Layout",

  data() {
    return {
      pageLoader: new PageLoader(26),
    };
  },

  components: {
    KTAside,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    Loader
  },

  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // get version
    this.$store.dispatch(POPULATE_VERSION)
      .then(() => {
        this.pageLoader.setCompleteComponent();
      })

    // get supported languages
    this.$store.dispatch(POPULATE_LANGUAGES)
      .then(() => {
        this.pageLoader.setCompleteComponent();
        this.$store.dispatch(POPULATE_DEFAULT_LANGUAGE, this.currentUser.system_language_code)
          .then(() => {
            this.pageLoader.setCompleteComponent();
          })
      });

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },

  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    const self = this;

    this.postLoadStates.forEach(function (action) {
      self.$store.dispatch(action, false)
        .then(() => {
          self.pageLoader.setCompleteComponent();
        })
    });
  },

  methods: {},

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "currentUser"
    ]),

    /**
     * Determines if the page has loaded
     * @return {boolean}
     */
    loadingComplete() {
      return this.pageLoader.getStatus();
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    /**
     * An array of system states that need to be loaded in the mounted method
     * @return {array}
     */
    postLoadStates() {
      return [
        POPULATE_ACTIVITY_NOTIFICATIONS,
        POPULATE_ACTIVITY_SETTINGS,
        POPULATE_ACTIVITY_QUESTION_SETTINGS,
        POPULATE_ACTIVITY_STAGES,
        POPULATE_ACTIVITY_TYPES,
        POPULATE_ADMIN_ROLES,
        POPULATE_ANSWER_TYPES,
        POPULATE_CLIENT_ROLES,
        POPULATE_COUNTRIES,
        POPULATE_GENDERS,
        POPULATE_MARKUP_PIN_TYPES,
        POPULATE_NOTIFY_METHODS,
        POPULATE_PAGE_TYPES,
        POPULATE_PROFILE_QUESTIONS,
        POPULATE_PROJECT_ROLES,
        POPULATE_PROJECT_STAGES,
        POPULATE_PROJECT_USER_SAMPLES,
        POPULATE_PROJECT_TEMPLATE_TYPES,
        POPULATE_ROOM_SETUPS,
        POPULATE_SEGMENT_CLASSES,
        POPULATE_SOCIAL_BIAS,
        POPULATE_SOCIAL_SETTING,
        POPULATE_TIMEZONES,
        POPULATE_USER_REGISTER_TYPE,
        POPULATE_WIDGET_TYPES
      ]
    }
  }
};
</script>
