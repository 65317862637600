<template>
  <div
    class="subheader py-3 py-lg-8 subheader-transparent mb-5"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-baseline mr-5">
        <!--begin::Page Title-->
        <h2 class="subheader-title text-dark font-weight-bold my-2 mr-3">
          {{ title }}
        </h2>
        <!--end::Page Title-->
        <!--begin::Breadcrumb-->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
        >
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
              <div
                :key="`${i}-${breadcrumb.id}`"
                v-if="breadcrumb.route && canAccessRoute(breadcrumb.route)"
              >
                <a
                  v-if="breadcrumb.route.includes('http')"
                  :href="breadcrumb.route"
                  class="direct-route text-muted"
                >
                  {{ breadcrumb.title }}
                </a>
                <router-link
                  v-else
                  :to="breadcrumb.route"
                  class="router-route text-muted"
                >
                  {{ breadcrumb.title }}
                </router-link>
              </div>
              <span
                v-else
                :key="`${i}-${breadcrumb.id}`"
                class="no-route text-muted"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
        <ProjectStageLabel
          v-if="projectInfo.id != undefined && breadcrumbs.length > 0"
          :project="projectInfo"
        >
        </ProjectStageLabel>
        <!--end::Breadcrumb-->
      </div>

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <b-dropdown
          size="sm"
          id="kt_quick_search_toggle"
          variant="link"
          toggle-class="topbar-item text-decoration-none"
          no-caret
          right
          no-flip
          class="pr-0"
        >
          <template v-slot:button-content>
            <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-0">
              <span class="svg-icon svg-icon-xl svg-icon-primary">
                <inline-svg src="media/svg/icons/General/Search.svg" />
              </span>
            </div>
          </template>
          <b-dropdown-text tag="div" class="min-w-md-350px">
            <SearchDefault></SearchDefault>
          </b-dropdown-text>
        </b-dropdown>
        <!--end: Search -->
        <ProjectSelect />
        <Notifications />
        <Helpdesk />
        <LanguageSelection />
        <TimezoneSelection />
        <UserOptions />
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
import LanguageSelection from "@/modules/together-helpers/components/generic-features/LanguageSelection.vue";
import TimezoneSelection from "@/view/layout/subheader/toolbar/TimezoneSelection.vue";
import ProjectSelect from "@/modules/together-helpers/components/generic-features/ProjectSelection.vue";
import Notifications from "@/modules/together-helpers/components/generic-features/Notifications.vue";
import UserOptions from "@/view/layout/subheader/toolbar/UserOptions.vue";
import Helpdesk from "@/modules/together-helpers/help/Helpdesk.vue";
import ProjectStageLabel from "@/view/components/ProjectStageLabel.vue";

export default {
  name: "KTSubheader",

  props: {
    breadcrumbs: Array,
    title: String
  },

  components: {
    Notifications,
    Helpdesk,
    LanguageSelection,
    TimezoneSelection,
    ProjectSelect,
    UserOptions,
    SearchDefault,
    ProjectStageLabel
  },

  methods: {
    canAccessRoute: function(route) {
      if (route.includes("/clients/view") && this.currentUser.menu_items.includes('DirectRootNavigation')) {
        return true;
      } else if (route.includes("/client/projects") && this.currentUser.menu_items.includes('DirectClientNavigation')) {
        return true;
      } else if (route.includes("/project/view")) {
        return true;
      } else {
        return false;
      }
    }
  },

  computed: {
    ...mapGetters([
      "layoutConfig",
      "projectInfo",
      "currentUser"
    ]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }

        if (this.layoutConfig("subheader.clear")) {
          classes.push("mb-0");
        }
      }
      return classes.join(" ");
    },

    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>
