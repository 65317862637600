<template>
  <div
    class="d-flex align-items-center justify-content-between mb-0 p-3 bg-hover-light"
    style="cursor: pointer"
    @click="navigateUser()"
  >
    
    <div class="d-flex flex-column">
      <span class="text-hover-primary font-weight-bold font-size-sm">
        {{ project.name }}
      </span>
      <div>
        <span class="text-muted font-weight-bold d-inline-block pr-1  font-size-sm">{{ project.key }} - </span>
        <span class="text-muted d-inline-block font-size-sm">{{ role }}</span>                    
      </div>
    </div>

    <span
      href="javascript:void(0)"
      class="label label-pill label-inline"
      :class="`label-light-${registration.state}`"
    >
      {{ registration.text }}
    </span>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

export default {
  name: "ProjectListItem",

  props: {
    project: { type: Object, required: true }
  },

  methods: {
    navigateUser: function() {
      let baseLink  = (this.project.pivot.system_project_role_id == 1) ? this.project.inputLink : this.project.adminLink;
      let slug      = null;

      if (this.registration.text == "subscribed") {
        slug = (this.project.pivot.system_project_role_id == 1) ? "/activities/view" : "/project/view";
      } else {
        slug = `/register/${this.project.pivot.setup_token}`;
      }

      window.location.href = `${baseLink}/#${slug}`;
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    role: function() {
      switch (this.project.pivot.system_project_role_id) {
        default:
        case 1:
          return "Participant";
        case 2:
          return "Observer";
        case 3:
          return "Community Manager";
      }
    },
    registration: function() {
      const self = this;

      if (this.currentUser.projects.filter(function(subscribedProject) {
        return subscribedProject.id == self.project.id
      }).length > 0) {
        return {
          state: "success",
          text: "subscribed"
        }
      } else {
        return {
          state: "warning",
          text: "invited"
        }
      }
    }
  }
}
</script>